import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';

// import ReactDOM from 'react-dom/client';
// import './index.css';
import App from '../App';
import CardX from '../component/card.js'
import PlaceX from '../component/place.js';

import reportWebVitals from '../reportWebVitals';
import { styled } from '@mui/material/styles';
import { Grid, Box, Paper, Button, Card, Container, Typography, CardMedia, Fab, Divider, Stack } from '@mui/material';
import ScrollIntoView from 'react-scroll-into-view'

// import { BrowserRouter as Router, Routes, Route }
// 	from 'react-router-dom';
// import Map_page from './map.js';

let sphere = null;
let map = null;

const Map_page = () => {

  const gold = '#C89F65';

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleWindowResize);

    const url = 'https://vallaris.dragonfly.gistda.or.th/core/api/maps/coverage/1.0-beta/maps/65fa6ac0627f1e6aa9c87c44/tms/{z}/{x}/{y}';

    //-- sphere --//
    sphere = window.sphere;
    map = new sphere.Map({ placeholder: document.getElementById('map-sphere'), zoomRange: { min: 10, max: 20 }, });
    map._this = this;
  
    map.Event.bind("ready", function () {
      // 13.822828452780435, 100.42614685734245
      // 13.671222816371175, 100.54865013164189
        map.bound({ minLon: 100.42614, minLat: 13.67122, maxLon: 100.54865, maxLat: 13.82282 }, { padding: { top: 7, bottom: 7 } });
        map.Ui.DPad.visible(false);
      //   map.Ui.LayerSelector.visible(false);
      //   map.Ui.Geolocation.visible(false);
      //   map.Ui.Zoombar.visible(false);
  
        let layer = new sphere.Layer('', {
            type: sphere.LayerType.XYZ,
            url: 'https://vallaris.dragonfly.gistda.or.th/core/api/maps/coverage/1.0-beta/maps/65fa6ac0627f1e6aa9c87c44/tms',
            id: 'layer',
            extraQuery: 'api_key=VJJguce0IYzIGdHQeml1Hu7R5lXWNLK84CdeGTQWbQqnwAMhpNk8t5yQwJFKX1Z8'
        });
  
        map.Layers.add(layer);
        console.log('add');
    });


  }, []);


  return (
    
        <Grid>
            <Box id="map-sphere" height={windowSize[1]} width={windowSize[0]}></Box>
        </Grid>
      
    
  );
}

export default Map_page;
