import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Home from './pages';
import Map_page from './pages/map.js';
import { createBrowserHistory } from "history";

const hist = createBrowserHistory();

function App() {
	return (
		<Router>
			{/* <Nav /> */}
			<Routes history={hist}>
				<Route exact path='/' element={<Home />} />
				<Route path='/map' element={<Map_page />} />
				{/* <Route path='/download' element={<Download />} /> */}
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		</Router>
	);
}

export default App;
