import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, Typography, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';


const CardX = ({ t1, t2, i, l }) => {

  const gold = '#C89F65';

  let theme = createTheme({
    typography: {
      fontFamily: 'Kanit',
    },
  });
  theme = responsiveFontSizes(theme);

  const StyledCard = styled(Card)(({ theme }) => ({
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1.0)" },
  }))


  return (
    <StyledCard>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          // height={md:"200", sx:"100"}
          image={i}
          sx={{ height: { md: 200, xs: 100 } }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: { md: '100px', xs: '50px' },
            background: 'linear-gradient(to bottom, transparent, #000000)',
            // color: 'white',
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography sx={{
              typography: { md: 'h4', xs: 'body1' },
            }} align='center' color={gold}><Box fontWeight='fontWeightMedium' display='inline'>{l === 1 ? t1 : ''}</Box></Typography>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Typography sx={{
              typography: { md: 'h4', xs: 'body1' },
            }} align='center' color={gold}><Box fontWeight='fontWeightMedium' display='inline'>{l === 0 ? t2 : ''}</Box></Typography>
          </ThemeProvider>
        </Box>
      </Box>
    </StyledCard>
  );
};


export default CardX;