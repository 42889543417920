import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Typography, CardMedia, Button, Container } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';


import download from '../svg/download.svg'


const PlaceX = ({ t1, t2, t3, t4, l, i0, i2, i4, i8, di2, di4, di8, windowSize }) => {

    let theme = createTheme({
        typography: {
            fontFamily: 'Kanit',
        },
    });
    theme = responsiveFontSizes(theme);


    const gold = '#C89F65';
    const selected = '#4E4646';

    const [is8000, setIs8000] = useState(false);
    const [is4000, setIs4000] = useState(false);
    const [is2000, setIs2000] = useState(true);

    return (
        <Box sx={{ position: 'relative' }}>

            <CardMedia
                component="img"
                height={windowSize[1] * 2.1}
                minHeight='100%'
                image={i0}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: windowSize[1] / 2,
                    width: '100%',
                    background: 'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.9))',
                    color: 'white',
                }}
            />

            <Box
                sx={{
                    position: 'absolute',
                    bottom: windowSize[1] * 1.1,
                    left: 0,
                    height: windowSize[1] / 2,
                    width: '100%',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.9))',
                    color: 'white',

                }}
            >
                <ThemeProvider theme={theme}>
                    <Typography sx={{
                        typography: { md: 'h1', xs: 'h2' },
                        textWrap: 'wrap',
                        fontWeight: '500',
                    }} align='center' color={gold} ><Box fontWeight='fontWeightMedium' display='inline'>{l === 1 ? t1 : ''}</Box></Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                    <Typography sx={{
                        typography: { md: 'h1', xs: 'h2' },
                        fontWeight: '500',
                        textWrap: 'warp',
                    }} align='center' color={gold} ><Box fontWeight='fontWeightMedium' display='inline'>{l === 0 ? t2 : ''}</Box></Typography>
                </ThemeProvider>

            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: windowSize[1] * 1.0,
                    left: 0,
                    height: windowSize[1] * 1.1,
                    width: '100%',
                    background: 'rgba(0,0,0,0.9)',
                    color: 'white',

                }}
            >
                <Container >
                    <Grid xs={12}>
                        <Box height={50}></Box>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={1} />

                        <Grid xs={11} sm={6}>
                            <Grid item xs={1} />

                            <Grid item xs={10} >
                                <Box
                                    component="img"
                                    sx={{
                                        borderRadius: 4,
                                        height: 300,
                                        width: 500,
                                        maxWidth: { xs: windowSize[0] * 0.8, sm: windowSize[0] * 0.5, md: windowSize[0] * 0.8 },
                                        maxHeight: { xs: windowSize[1] * 0.2, sm: windowSize[1] * 0.4 },
                                        objectFit: 'cover',
                                        // maxHeight: { xs: 233, md: 167 },
                                        // maxWidth: { xs: 350, md: 250 },
                                    }}
                                    src={is8000 ? i8 : is4000 ? i4 : i2}
                                />
                            </Grid>
                            <Grid item xs={1} />

                        </Grid>

                        <Grid item xs={1} />

                        <Grid xs={11} sm={4}>
                            <Grid item xs={1} />

                            <Grid item xs={10} container spacing={1}>
                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <Button item variant="contained" sx={{ borderRadius: 28 }} style={is8000 ?
                                            { backgroundColor: selected, width: { md: '130px', xs: '60px' }, height: { md: '60px', xs: '30px' }, }
                                            : { backgroundColor: "#FFF", width: { md: '130px', xs: '60px' }, height: { md: '60px', xs: '30px' }, }}
                                            onClick={() => { setIs8000(true); setIs4000(false); setIs2000(false) }}
                                        > <Typography align='center' color={gold} sx={{ typography: { md: 'h5', xs: 'body1' } }}>1 : 8,000</Typography></Button>
                                    </Grid>
                                    <Grid item>
                                        <a
                                            href={di8}
                                            download={`${t1}_8000`}
                                            target="_blank"
                                        >
                                            <Button item variant="contained" sx={{ borderRadius: 28 }} style={{
                                                backgroundColor: "#FFF", width: { md: '200px', xs: '100px' }, height: { md: '60px', xs: '30px' },
                                            }}
                                            // onClick={() => getimage(i8)}
                                            >
                                                <img height={24} src={download} />
                                                <Typography align='center' color={gold} sx={{ typography: { md: 'h5', xs: 'body1' } }}>Download</Typography>
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>

                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <Button item variant="contained" sx={{ borderRadius: 28 }} style={is4000 ?
                                            { backgroundColor: selected, width: { md: '130px', xs: '60px' }, height: { md: '60px', xs: '30px' }, }
                                            : { backgroundColor: "#FFF", width: { md: '130px', xs: '60px' }, height: { md: '60px', xs: '30px' }, }}
                                            onClick={() => { setIs8000(false); setIs4000(true); setIs2000(false) }}
                                        > <Typography align='center' color={gold} sx={{ typography: { md: 'h5', xs: 'body1' } }}>1 : 4,000</Typography></Button>
                                    </Grid>
                                    <Grid item>
                                        <a
                                            href={di4}
                                            download={`${t1}_4000`}
                                            target="_blank"
                                        >
                                            <Button item variant="contained" sx={{ borderRadius: 28 }} style={{
                                                backgroundColor: "#FFF", width: { md: '200px', xs: '100px' }, height: { md: '60px', xs: '30px' },
                                            }}
                                            // onClick={() => getimage(i4)}
                                            >
                                                <img height={24} src={download} />
                                                <Typography align='center' color={gold} sx={{ typography: { md: 'h5', xs: 'body1' } }}>Download</Typography>
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>

                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <Button item variant="contained" sx={{ borderRadius: 28 }} style={is2000 ?
                                            { backgroundColor: selected, width: { md: '130px', xs: '60px' }, height: { md: '60px', xs: '30px' }, }
                                            : { backgroundColor: "#FFF", width: { md: '130px', xs: '60px' }, height: { md: '60px', xs: '30px' }, }}
                                            onClick={() => { setIs8000(false); setIs4000(false); setIs2000(true) }}
                                        > <Typography align='center' color={gold} sx={{ typography: { md: 'h5', xs: 'body1' } }}>1 : 2,000</Typography></Button>
                                    </Grid>
                                    <Grid item>
                                        <a
                                            href={di2}
                                            download={`${t1}_2000`}
                                            target="_blank"
                                        >
                                            <Button item variant="contained" sx={{ borderRadius: 28 }} style={{
                                                backgroundColor: "#FFF", width: { md: '200px', xs: '100px' }, height: { md: '60px', xs: '30px' },
                                            }}
                                            // onClick={() => getimage(i2)}
                                            >
                                                <img height={24} src={download} />
                                                <Typography align='center' color={gold} sx={{ typography: { md: 'h5', xs: 'body1' } }}>Download</Typography>
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid margin={5}>
                        <Typography align='left' color={gold} variant="h5">{l === 1 ? t1 : l === 0 ? t2 : ''}</Typography>

                        <Typography sx={{
                            typography: { md: 'body1', xs: 'body2' },
                        }} align='left' color='#FFF'>{l === 1 ? t3 : ''}</Typography>
                        <Typography sx={{
                            typography: { md: 'body1', xs: 'body2' },
                        }} align='left' color='#FFF'>{l === 0 ? t4 : ''}</Typography>
                    </Grid>
                </Container>
            </Box>

        </Box >

    );
};


export default PlaceX;